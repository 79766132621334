import React from "react";
import { Result } from "antd";

const FailedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Result
          status="error"
          title="Payment failed"
          subTitle={
            <p style={{ fontSize: 16 }}>
              Payment failed. Please try again to complete your transaction.
            </p>
          }
        />
      </div>
    </div>
  );
};

export default FailedPage;
