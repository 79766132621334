import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MerchantSignUp from "./page/merchant-signup";
import ErrorPage from "./page/404.page";
import SuccessPage from "./page/success.page";
import FailedPage from "./page/failed.page";
import ActivationPage from "./page/activation.page";
import OtpPage from "./page/otp.page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<MerchantSignUp />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/failed" element={<FailedPage />} />
        <Route path="/activate" element={<ActivationPage />} />
        <Route path="/otp" element={<OtpPage />} />
      </Routes>
    </Router>
  );
}

export default App;
